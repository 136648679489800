import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://b5d0b0762fe5f776e478720f26df6325@o1053080.ingest.sentry.io/4506231715332096",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});